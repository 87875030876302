export default {
  isoName: 'es',
  common: {
    admins_page: {
      success_save_admin: '¡El administrador guardó exitosamente!',
      delete_success_admins: '¡Administradores eliminados exitosamente!'
    },
    time_group_page: {
      time_group_success_save: '¡El grupo de tiempo se guardó correctamente!',
      success_delete_group: '¡Grupo eliminado exitosamente!',
      success_delete_batch_group: '¡Grupos eliminados exitosamente!'
    },
    country: 'País',
    previously: 'PREVIAMENTE',
    general_error: 'Ha ocurrido un error en el servidor',
    dashboard: 'Tablero',
    report: 'Reporte | Reportando',
    documentation: 'Documentación',
    changelog: 'Registro de cambios',
    status: 'Status',
    premium: 'Premium',
    artificial_intelligence: 'Inteligencia artificial',
    contexts: 'Contextos',
    quota: 'Cuota de API',
    integrate: 'Integrar',
    start_hour: 'Hora de inicio',
    business_hours: 'Horario comercial',
    interval: 'Intervalo',
    update_interval: 'Intervalo estándar',
    initial: 'inicial',
    final: 'Final',
    end_hour: 'Hora de término',
    security_privacy: 'Seguridad y privacidad',
    flow: 'Fluir',
    channel: 'Canal | Canales',
    batch: 'Envío por lotes',
    contact: 'Contacto | Contactos',
    config: 'Ajustes | Ajustes',
    help: 'Ayuda',
    about: 'Acerca de',
    required: 'Obligatorio',
    login: 'Acceso',
    check_account: 'Cuenta de cheques',
    preview: 'Avance',
    add_variable: 'Agregar variable',
    bold: 'Negrita',
    italic: 'Cursiva',
    strike: 'Tachado',
    underline: 'Subrayado',
    strikethrough: 'Tachado',
    paste: 'Pegar',
    generate_token_auth: 'Generar token de autenticación',
    generate_api_key: 'Generar ApiKey',
    copy_token_auth: 'Copiar token de autenticación',
    recover_password: 'Recuperar contraseña',
    indicator: 'Indicador | Indicadores',
    message_templates: 'Plantillas de mensajes',
    storage: 'Almacenamiento',
    monitoring: 'Supervisión',
    reauthentication: 'Reautenticación',
    teams_gtw: 'Habilitar la Teams Gateway Interface (Beta)',
    enable_batch_delivery: 'Habilitar envío por lotes',
    name: 'Nombre',
    url: 'URL',
    approved: 'Aprobado',
    in_analysis: 'En análisis',
    pending: 'Pendiente',
    unsynchronized: 'Desincronizado',
    refused: 'Rechazado',
    filter: 'Filtrar',
    apply: 'Aplicar',
    clean: 'Limpiar',
    header: 'Encabezamiento',
    body: 'Cuerpo',
    value: 'Valor',
    parameter: 'Parámetro | Parámetros',
    email: 'Correo electrónico',
    login_email: 'Correo electrónico de acceso',
    recovery_email: 'Correo electrónico de recuperación',
    attendance: 'Asistencia | Atenciones',
    agent: 'Agente | Agentes',
    conversation: 'Hablar | Conversaciones',
    export: 'Exportar',
    variable: 'Variable | Variables',
    audit: 'Auditoría',
    password: 'Contraseña',
    redefine_password: 'Redefinir contraseña',
    current_password: 'Contraseña actual',
    new_password: 'Nueva contraseña',
    confirm_password: 'Confirmar nueva contraseña',
    remember: 'Recordar',
    log_in: 'Entra',
    contact_center: 'DESCUBRE EL NUEVO CENTRO DE CONTACTO',
    message_extract: 'Extracto de mensaje',
    team: 'Equipo | Equipos',
    pause: 'Pausa | Pausas',
    tabulation: 'Pestaña | Pestañas',
    tags: 'Tag | Tags',
    aplication: 'Solicitud',
    multichannel: 'Multicanal',
    import_contacts: 'Importar contactos',
    time_group: 'Grupo de tiempo',
    rules: 'Regla | Reglas',
    attention: 'Atención',
    automatic_answer: 'Respuesta automática',
    attendance_automatic: 'Asistencia automático',
    default_team: 'Equipo predeterminado',
    initial_message: 'Mensaje inicial',
    api: 'API',
    rest: 'REST',
    third: 'La tercera',
    rd_station: 'RD Station (beta)',
    telephone_exchange: 'Central telefónica',
    client_secret: 'Secret key',
    app_id: 'Application identifier',
    tenant_id: 'Tenant id',
    integration: 'Integración | Integraciones',
    user_group: 'Grupo de usuario',
    add: 'Agregar',
    additional_fields: 'Campos Adicionales',
    word_filter: 'Filtro de palabras',
    remote_support: 'Soporte remoto',
    support: 'Suporte',
    custom_variables: 'Variables personalizadas',
    administrator: 'Administrador | Administradores',
    settings: 'Settings',
    page: 'Página | Paginas',
    hsm: 'HSM',
    hsm_template: 'Plantillas de mensajes',
    select: 'Seleccione',
    client_secret: 'Secret key',
    app_id: 'Application identifier',
    forgot_password: 'olvido la contraseña?',
    login_title: 'Área de inicio de sesión',
    language: 'Idioma',
    search: 'Buscar',
    description: 'Descripción',
    method: 'Método',
    auth_method: 'Método de Autentificación',
    all: 'Todos',
    agents_only: 'Solo agentes',
    admins_only: 'Solo administradores',
    none: 'Ninguno',
    rows_per_page: 'Filas por página',
    tip: 'Consejo | Consejos',
    type: 'Tipo | Tipos',
    default: 'Patrón',
    version: 'Versión',
    number: 'Número | Números',
    spacing: 'Espaciado | Espacios',
    send_schedule: 'Enviar horario',
    send_media_with_link: 'Enviar medios con enlace',
    able: 'Capaz',
    activate: 'Activar',
    disabled: 'Discapacitado',
    upload_icon: 'Subir icono',
    form_title: 'Título del formulario',
    icon_color: 'Color del icono',
    label: 'Etiqueta',
    color: 'Color',
    font_color: 'Color de fuente',
    left: 'Izquierda',
    right: 'Derecha',
    notification: 'Notificación',
    gradually_appear: 'Poco a poco aparecen',
    increase_rotated: 'Aumentar girado',
    message_box: 'Caja de mensaje',
    ad_account: 'Cuenta publicitaria',
    ending_message: 'Mensaje final',
    enter_your_message: 'Ingrese su mensaje',
    increase: 'Incrementar',
    appearance: 'Apariencia',
    button_color: 'Color del boton',
    alert_color: 'Color de alerta',
    pop_up_color: 'Color del pop-up de finalización de servicio',
    main_color: 'Color principal',
    slide: 'Diapositiva',
    title: 'Título',
    turn_on: 'Encender',
    optional: 'Opcional',
    clone: 'Clon',
    resend: 'Reenviar',
    example_template: 'Plantilla de ejemplo',
    remove_session: 'Eliminar sesión',
    characters: 'Caracteres',
    option_title: 'Título de la opción',
    product_id: 'Id del producto',
    add_session: 'Agregar sesión',
    list_title: 'Título de la lista',
    see_items: 'Ver artículos',
    multiproduct: 'Multiproductos',
    without_permission: 'Sin permiso',
    not_applicable: 'No se aplica',
    caption: 'Subtítulo',
    text: 'Texto',
    time: 'Tiempo',
    message: 'Mensaje',
    seconds: 'Segundos',
    minutes: 'Minutos',
    list: 'Lista',
    card: 'Tarjeta',
    category: 'categoría',
    visibility: 'Visibilidad',
    models: 'Modelo | Modelos',
    contents: 'Contenido',
    close: 'Cerrar',
    conclude: 'Concluir',
    type_action: 'Tipo de acción',
    message_body: 'Cuerpo del mensaje',
    media_type: 'Tipo de medio',
    header_type: 'Tipo de encabezado',
    customization: 'Personalización',
    input_fields: 'Campos de entrada',
    footer: 'Zócalo',
    button: 'Botón',
    buttons: 'Botones',
    button_text: 'Botón de texto',
    image: 'Imagen',
    position: 'Posición',
    animation: 'Animación',
    icon: 'Icono',
    light: 'Claro',
    dark: 'Oscuro',
    token: 'Token',
    add_option: 'Agregar opción',
    add_product: 'Agregar producto',
    botname: 'Nombre del bot',
    botlink: 'enlace bot',
    receptive: 'Receptivo',
    account_name: 'Nombre de la cuenta',
    ra_page_id: 'Empresa RA',
    ra_success:
      'Autenticado con éxito en Reclame Aqui. Elija la página de abajo.',
    apikey_generate_success: 'Su apikey se generó con éxito.',
    account_password: 'Contraseña de la cuenta',
    control: 'Control',
    post: 'Correo',
    comment: 'Comentario | Comentarios',
    period: 'Curso del tiempo',
    day: 'Día | Dias',
    hour: 'Hora | Horas',
    week: 'Semana | Semanas',
    file: 'Archivo | Archivos',
    yes: 'Sí',
    no: 'No',
    announcement: 'Anuncio',
    provider: 'Proveedor',
    port: 'Puerta',
    host: 'Host',
    protocol: 'Protocolo',
    user: 'Usuario | Usuarios',
    hash: 'Hash',
    other: 'Otro | Otros',
    copy_identifier: 'Copiar identificador',
    copy: 'Copiar',
    copy_webhook: 'Copiar webhook',
    authenticate: 'Autenticar',
    activation_code: 'Código de Activación',
    restart: 'Reiniciar',
    introduction: 'Introducción',
    function: 'Función',
    advance: 'Avance',
    previous_page: 'Volver a la página anterior',
    starting_process: 'Proceso de inicio',
    pending_auth: 'Pendiente',
    qrcode: 'QrCode',
    enable_qrcode: 'Habilitar QrCode',
    connected: 'Conectado',
    disconnected: 'Desenchufado',
    smtp_error: 'Error de SMTP',
    error_imap_and_smtp: 'Error de SMTP e IMAP',
    error_imap: 'Error de IMAP',
    login_or: 'o',
    of: 'en',
    oauth_redirect_url: 'Redireccionar URI',
    webhook_url: 'URL de devolución de llamada de notificación',
    aware: 'Consciente',
    unlock_for_trust: 'Desbloquea con confianza',
    access_now: 'Acceder ahora',
    authenticationMethod: 'Método de autentificación',
    mode: 'Tipo de función',
    test: 'Prueba',
    result: 'Resultado',
    custom: 'Personalizable',
    script_test: 'Prueba de guión',
    lang: {
      'pt-br': 'Portugués',
      es: 'Español',
      en: 'Inglés'
    },
    list_colors: {
      yellow: 'Amarillo',
      blue: 'Azul',
      green: 'Verde',
      grey: 'Gris',
      purple: 'Púrpura',
      brown: 'Marrón',
      red: 'Rojo',
      violet: 'Violeta',
      pink: 'Rosa',
      black: 'Negro'
    },
    components: {
      facebook_reaction: 'Reacción de  Facebook',
      attachment: 'Adjunto archivo',
      receive_attachment: 'Recibir archivo adjunto',
      menu: 'Menú',
      webchat_event: 'Evento de chat web',
      location: 'Localización',
      service_note: 'Nota de servicio',
      condition: 'Condición',
      input: 'Entrada de datos',
      template_message: 'Plantilla de mensaje'
    },
    actions: {
      self: 'Acione | Aciones',
      edit: 'Para editar',
      remove: 'Para eliminar',
      save: 'Ahorrar',
      back: 'Regresar',
      cancel: 'Cancelar'
    },
    messages: {
      required_office365: 'Solo para usuarios de office365',
      image_size: 'La imagen debe tener un tamaño de 192x192 píxeles',
      auth_verify: 'Verificación de la autenticación',
      invalid_character: 'Carácter invalido!',
      automatic_spacing:
        'El espaciado automático en los mensajes evita prohibir WhatsApp',
      required: '{name} ¡es obligatorio!',
      can_be_no_space: 'El {name} no puede tener espacios!',
      view_mode: 'modo de vista {name}',
      invalid: '{name} ¡No es válido!',
      licence_limit_per_channel_reached:
        'Límite de licencia por canal alcanzado',
      channel_already_exists_in_another_licence:
        'El canal ya existe en otra licencia',
      email_settings_not_found:
        'Configuración de correo electrónico no encontrada',
      reauthentication_required: 'Se requiere reautenticación',
      flow_invalid:
        'El flujo definido tiene componentes que el canal no admite',
      no_data: 'Ninguno {name} registrado!',
      incomplete_phone_number: 'DDI + DDD es obligatorio',
      channel_period:
        'Establezca cuántos días desea mostrar en el historial de comentarios',
      restart_channel: '¿Realmente quieres reiniciar?',
      no_result: 'Ninguno resultado encontrado!',
      url_webhook: 'URL del webhook no encontrada!',
      are_you_sure: 'Estás seguro?',
      button_customization: 'Personalización de botones',
      chat_customization: 'Personalización del chat',
      success: '¡Hecho con éxito!',
      copy: '¡Copiado con éxito!',
      error: '¡Ocurrio un error!',
      error_email: '¡Ocurrió un error al autenticar el correo electrónico.',
      password_not_match: 'Las contraseñas no coinciden',
      message_color: 'Color del mensaje de usuario',
      can_i_help_you: '¿Puedo ayudar?',
      you_really_delete: '¿De verdad quieres eliminar?',
      really_want_to_resend:
        '¿Realmente desea volver a enviar la plantilla de mensaje para su aprobación?\nEl análisis puede tardar hasta 24 horas.',
      redirect_to_update:
        'No se pudieron verificar todos los canales para reenviar. Será redirigido a la pantalla de edición para seleccionar canales manualmente. ¿Desea continuar?',
      session_end: 'Terminar sesión',
      you_really_end_service: '¿De verdad quieres terminar tu servicio?',
      edit_user_information: 'Editar la in>formación del usuario',
      only_cell: 'solo en celulares',
      select_image_button: 'Seleccione una imagen para el botón',
      character_limit: 'Límite de caracteres por mensaje',
      welcome_message: 'Mensaje de bienvenida',
      select_default_image: 'Seleccione una imagen predeterminada para el chat',
      selected_platform:
        'La plataforma seleccionada no puede usar una transmisión que contenga los siguientes componentes',
      unlock_for_trust:
        'El desbloqueo de confianza durará 48 horas. Póngase en contacto con la persona responsable.',
      alert_newsletter:
        'Alertas del formulario de inicio que se muestran cuando un campo obligatorio no se completa o cuando la información se ingresa en un formato incorrecto.'
    }
  },
  signin_message: {
    login_back: 'Atrás para iniciar sesión',
    auth_remote_message:
      'El usuario ya no tiene permiso de inicio de sesión remoto, restablezca una contraseña para obtener acceso al sistema.',
    username_password_match:
      'El nombre de usuario y / o la contraseña no coinciden',
    expired_password:
      'Su contraseña ha caducado, ¡registre una nueva contraseña!',
    lock_prediction:
      'Su sistema se bloqueará en {days} días. Para evitar el bloqueo, póngase en contacto con la persona responsable.',
    locked_system:
      'Su sistema ha sido bloqueado. Para seguir accediendo a la plataforma con normalidad, contacte con el responsable.'
  },
  hsm_screen: {
    messages: {
      image_size_limit: 'El tamaño de la imagen no puede superar los 1.5 MB',
      body_example: 'Ejemplo de cuerpo de texto',
      enable_device: '¿Quieres habilitar la plantilla para el dispositivo?',
      disable_device: '¿Quieres deshabilitar la plantilla para el dispositivo?',
      no_result_channel: '¡No se agregaron canales!',
      updated_variables: 'Variables actualizadas',
      message_required: '¡El cuerpo de mensaje es obligatorio!',
      no_result_dialog: 'No se ha encontrado ningún canal de diálogo!',
      template_example_message:
        'Envíe su plantilla con ejemplos para aumentar las posibilidades de aprobación',
      alert_example_message:
        'Los ejemplos se cargarán en Facebook como parte del proceso de aprobación de la plantilla de mensaje. si ninguno Se informa un ejemplo, los modelos pueden ser rechazados. pelo Facebook.'
    },
    no_registration_variable:
      'Este registro no tiene las variables rellenadas, ¡corríjalo para usarlo normalmente!',
    type_repetition: '¡Los valores de tipo en tipo de acción se repiten!',
    status_error: 'Error',
    status_reject: 'Rechazada',
    in_analysis: 'Este modelo está siendo analizado por un equipo de Meta.',
    type_to_replace: 'Escriba para reemplazar:',
    template_exists: 'Ya existe una plantilla con ese nombre.',
    select_template: 'Seleccione una plantilla para comenzar.',
    select_models: 'Seleccione una de las plantillas para continuar',
    choose_model: 'Elegir modelo',
    button_type_quick_reply: 'Respuestas Rápidas',
    button_type_action: 'Acciones',
    phone: 'Teléfono',
    head_type_none: 'Ninguno',
    head_type_text: 'Texto',
    head_type_image: 'Imagen',
    head_type_document: 'Documento',
    head_type_video: 'Video',
    message_button_limit: 'Se alcanzó el límite máximo de botones.',
    message_option_limit: 'Límite máximo de opciones alcanzado.',
    message_section_limit: 'Límite máximo de sessiones alcanzado.',
    message_example_not_found: 'Ingrese un ejemplo para cada variable.',
    message_device_not_found: 'No hay canales habilitados.'
  },
  hsm_categories: {
    account_update: 'Actualización de cuenta',
    payment_update: 'Actualización de pago',
    personal_finance_update: 'Actualización de finanzas personales',
    shipping_update: 'Subir actualización',
    reservation_update: 'Actualización de reserva',
    issue_resolution: 'Solución de problemas',
    appointment_update: 'Actualización de la cita',
    transportation_update: 'Actualización de transporte',
    ticket_update: 'Actualización de ticket',
    alert_update: 'Alerta de actualización',
    auto_reply: 'Respuesta automática'
  },
  hsm_visibility: {
    all: 'Todos',
    agents_only: 'Solo agentes',
    admins_only: 'Solo administradores',
    none: 'Ninguna'
  },
  marketplace_screen: {
    messages: {
      expand_power: 'Expanda su poder con integraciones de aplicaciones',
      marketplace_collection:
        'Nuestro mercado es una colección de aplicaciones para expandir el poder de la plataforma y mejorar su desempeño.',
      choose_below:
        'Elija a continuación el modo en el que desea iniciar su nueva integración.'
    }
  },
  channel_screen: {
    my_business: {
      configuration_auth: 'Configuración de Autenticación'
    },
    messages: {
      generic_type: 'Tipo de canal genérico',
      webhook: {
        inbound: 'Hook de entrada',
        outbound: 'Hook de salida'
      },
      include_channel: 'Incluir Canal',
      edit_channel: 'Editar canal',
      check_account: '¡La cuenta de Instagram Direct es comercial!',
      webhook_register:
        'El campo Webhook estará disponible para su visualización después de registrar con éxito este canal',
      configuration_bsp: 'Configuración BSP',
      session_open_channel_remove:
        'Hay sesiones abiertas para este canal, es necesario eliminar las sesiones antes de finalizar la eliminación',
      generic_channel_edit: 'Editar canal genérico (Beta)',
      generic_channel_add: 'Incluir canal genérico (Beta)',
      wait_one_minutes: 'Espere 1 minuto para realizar la modificación',
      enable_receptive: '¿Quieres habilitar lo receptivo?',
      disable_receptive: '¿Quieres inhabilitar lo receptivo?',
      enable_channel: '¿Quieres habilitar este canal?',
      disable_channel: '¿Quieres desactivar este canal?',
      token_generate:
        'Al generar un nuevo token, los antiguos dejarán de funcionar. ¿Realmente quieres generar un nuevo token?',
      apikey_generate:
        'Al generar una nueva Api Key, las antiguas dejaban de funcionar. ¿Realmente quieres generar una nueva ApiKey?',
      apikey_info: 'Espera 1 minuto mientras actualizamos tu apikey.'
    },
    teams: {
      microsoft_app_id: 'Microsoft App ID',
      microsoft_app_password: 'Microsoft App Password',
      microsoft_app_tenant_id: 'Tenant ID'
    },
    webchat: {
      ask_information: 'Solicita información antes de iniciar el chat',
      image_dimension:
        'El valor ingresado para el campo del icono no es una dimensión de imagen válida.',
      display_button_message:
        '¿Mostrar mensaje de botón después de cuántos segundos?',
      display_button_chat:
        '¿Mostrar el botón de chat después de cuántos segundos?',
      choose_animation: 'Elija la animación de la vista de chat',
      keep_keyboard:
        '¿Mantener el teclado abierto después de enviar un mensaje?',
      allow_ballon: 'Permitir que se cierre el mensaje de globo',
      display_welcome_message:
        'Mostrar mensaje de bienvenida al iniciar el chat web',
      show_message_chat: 'Mostrar mensaje junto al chat',
      enable_active_webchat: 'Habilitar el chat web activo (Beta)',
      information_starting:
        'Necesitamos información antes de iniciar el servicio.',
      display_online:
        'Mostrar como en línea si uno o más agentes están conectados.',
      select_service:
        'Seleccione los servicios que mostrarán el chat en línea. si tiene agentes conectados. Dejar en blanco para Todas las llamadas',
      webchat_users_notification:
        'Esta función permite una conversación bidireccional con usuarios de chat web, como recibir notificaciones de nuevos mensajes, historiales y registro unificado. Una vez habilitado, Webchat ofrece al usuario la posibilidad de opción para instalar en su pantalla de inicio funcionando como Una aplicación.',
      close_chat_after:
        '¿Cerrar el chat después de que el usuario haya cerrado la sesión?',
      select_image_webchat:
        'Seleccione una imagen para el icono de Webchat. La imagen debe tener un tamaño de 512x512 píxeles.'
    }
  },
  webchat: {
    tip:
      'Deberá insertar el código una vez e>n cada página donde desee mostrar el Webchat. Inserta el código de arriba en la etiqueta (o en la parte superior del cuerpo) de tu página. Si necesita un enlace directo para compartir con sus clientes, utilice el enlace a continuación.'
  },
  active_contact: {
    active_contact_title:
      '¡Elija el tipo de plantilla de mensaje que desea utilizar para iniciar una nueva conversación!',
    active_contact_confirmation: '¡Confirme la plantilla de mensaje elegida!',
    models_not_found: '¡No se encontraron modelos!',
    labels: {
      campaigns: {
        label: 'Equipos',
        placeholder: 'Seleccione un equipo'
      },
      channels: {
        label: 'Canales',
        placeholder: 'Seleccione un canal'
      },
      active_sessions: 'Sesiones activas',
      custom_variable:
        'El contenido de las variables presentes en el modelo se puede personalizar utilizando los campos a continuación',
      variables: 'Variables',
      filter: 'Filtro',
      name: 'Nombre',
      message: 'Mensaje',
      category: 'Categoría'
    },
    customer_care: {
      title: 'Atención al cliente',
      description: 'Tiempo restante desde el último mensaje del usuario'
    },
    click_to_whatsapp: {
      title: 'Click para WhatsApp (CTWA)',
      description: 'Conversación iniciada por campaña'
    },
    service: {
      title: 'Servicio',
      description: 'Conversación iniciada por el negocio',
      tip: 'Te permite resolver las dudas de los clientes.'
    },
    marketing: {
      title: 'Marketing',
      description: 'Conversación iniciada por el negocio',
      tip:
        'Te permite alcanzar una amplia gama de objetivos, desde generar reconocimiento hasta impulsar ventas y redirigir clientes. Los ejemplos incluyen anuncios de nuevos productos, servicios o características, promociones/ofertas dirigidas y recordatorios de abandono de carrito.'
    },
    utility: {
      title: 'Utilidad',
      description: 'Conversación iniciada por el negocio',
      tip:
        'Permite seguir las acciones o solicitudes del usuario. Los ejemplos incluyen confirmación de aceptación, gestión de pedidos/entregas (por ejemplo, actualización de entrega), actualizaciones o alertas de cuenta (por ejemplo, recordatorio de pago) o encuestas de feedback.'
    },
    authentication: {
      title: 'Autenticación',
      description: 'Conversación iniciada por el negocio',
      tip:
        'Te permite confirmar la identidad del usuario utilizando códigos de acceso desechables en varias etapas del proceso de inicio de sesión (por ejemplo, verificación y recuperación de la cuenta o desafíos de integridad).'
    },
    buttons: {
      cancel: 'Cancelar',
      next: 'Avanzar',
      back: 'Atrás',
      send: 'Enviar',
      apply: 'Aplicar',
      clear: 'Limpiar',
      confirm: 'Sí, confirmar',
      send_free_text: 'Enviar texto libre'
    },
    dialog: {
      title: '¿Quieres abrir una nueva sesión?',
      description:
        'Ya tienes una sesión activa en curso. Al iniciar una nueva sesión, se generará un cargo adicional.'
    }
  },
  authentication_template: {
    example_message: 'Hola, tu código de autenticación es <b>{{1}}</b>.',
    advisory_message: 'Para tu seguridad, no lo compartas.',
    button_message: 'Copiar código',
    footer_message: 'Este código expira en',
    example_footer: 'Este código expira en 10 minutos',
    code_delivery: {
      title: 'Entrega del código',
      description:
        'Meta persistirá en el intento de entregar un mensaje al usuario de WhatsApp durante el período designado como la vida útil del mensaje. Por defecto, las plantillas de autenticación tienen una vida útil de 10 minutos. Si la entrega no es exitosa dentro de este rango, los intentos se detendrán y el mensaje será descartado.'
    },
    copy_code: {
      title: 'Copiar código',
      description:
        'Autenticación básica simplificada. Sus clientes pueden copiar y pegar fácilmente el código en su aplicación para garantizar un acceso rápido y seguro.',
      button_info:
        'Los clientes tocan el botón para copiar y pegar el código en su propia aplicación.',
      button_tip:
        'La opción "Copiar código" funciona mejor para los clientes que solicitan el código en un dispositivo que no contiene su cuenta de WhatsApp.'
    },
    text_button: {
      title: 'Texto del botón',
      description:
        'Tienes la posibilidad de personalizar el texto del botón según tus preferencias y necesidades, adaptándolo de la manera que más te convenga.'
    },
    message_content: {
      title: 'Contenido del mensaje',
      description:
        'No es posible editar el contenido de las plantillas de mensajes de autenticación. Puedes agregar más contenido de las opciones a continuación.'
    },
    labels: {
      add_advisory: 'Agregar recomendación de seguridad',
      add_expires: 'Agregar tiempo de expiración del código',
      expires_in: 'Expira en',
      expires: 'Tiempo de expiración',
      variable: 'Variable',
      placeholder: 'Placeholder'
    }
  },
  forgot_password: {
    success:
      'Se envió un enlace para cambiar la contraseña al correo electrónico {email}, revise su bandeja de entrada en los próximos minutos.'
  },
  errors: {
    only_json_file: 'Solo se permiten archivos Json',
    access_denied: 'Acceso denegado',
    access_denied_subtitle:
      'Usted no tiene permiso para acceder a esta página.',
    not_found: 'Página no encontrada',
    not_found_subtitle: 'No se encontró la página solicitada.',
    failed_server_remote:
      'Error al autenticarse con SAML2. Autenticador sin atributo de correo electrónico configurado.',
    failed_authenticate_remote_client:
      'Error al autenticarse con SAML2. Cliente {host} no configurado / identificado.',
    failed_authenticate_unaccredited:
      'Error al autenticarse con SAML2. Usuario no regularizado.',
    failed_authenticate_profile:
      'Error al autenticarse con SAML2. Usuario sin perfil definido.',
    authenticate_remote_not_possible:
      'No puede autenticarse con el servidor remoto en ese momento.',
    no_active_team: 'No tienes un equipo activo',
    forgot_password_invalid_email:
      'No pudimos encontrar ninguna dirección de correo electrónico para recuperar la contraseña del usuario informado.',
    forgot_password_user_not_found:
      'No podemos encontrar ningún usuario con ese correo electrónico.',
    service_unavailable: 'Servicio no disponible',
    user_cannot_reset_password:
      'Para cambiar la contraseña de acceso, comuníquese con el administrador del proveedor de autenticación remota.',
    user_blocked_to_access:
      '¡Tu inicio de sesión está bloqueado temporalmente!',
    user_or_password_wrong:
      'El nombre de usuario y/o la contraseña no coinciden',
    user_or_password_wrong_with_recaptchav2:
      'El nombre de usuario y/o la contraseña no coinciden',
    current_password_is_invalid: '¡La contraseña actual no es válida!',
    passwords_do_not_match: '¡Las contraseñas ingresadas no coinciden!',
    password_dont_have_min_length:
      'La contraseña no tiene una longitud mínima de {minLength} caracteres.',
    password_dont_have_rules:
      'La contraseña debe tener al menos una letra, un número y un carácter especial.',
    password_have_numeric_sequence:
      'No es posible insertar una cadena numérica dentro de la contraseña. Ej .: ..012 .., ..654 .. ',
    password_have_chars_repeated:
      'No es posible insertar caracteres repetidos dentro de la contraseña. Ej .: ..aa .., ..22 .., ..-- .. ',
    password_dont_register_again:
      'No puede registrar una contraseña utilizada anteriormente',
    flow_not_exist: 'La flujo seleccionada no existe',
    ra_auth_error: 'Error al autenticarse en la API de Reclame Aqui!',
    channel_not_provided: 'Canal no encontrado',
    channel_generate_apikey_error: 'Tuvimos un problema al generar la apikey',
    recaptcha_low_score: 'Verifique el Recaptcha.',
    no_recaptcha_token_response: 'Error al verificar recaptcha.',
    max_sessions: 'Error al exceder el límite de sesión.',
    max_product_items: 'Error al exceder el límite del producto.',
    duplicate_product_id: 'Error al enviar ID de productos duplicados.',
    update_error_in_less_than_30_days:
      'La plantilla de mensaje no se puede actualizar más de 10 veces en menos de 30 días.',
    update_error_in_less_than_24_hours:
      'La plantilla de mensaje no se puede actualizar más de una vez en menos de 24 horas.',
    not_found_userhub:
      'No se encontró el usuario del canal, verifique si el canal está habilitado.',
    not_found_meta_id: 'La plantilla del mensaje no se encontró en el Meta.'
  },
  invalid: {
    login_disabled:
      'Su inicio de sesión está deshabilitado. ¡Comuníquese con su administrador!',
    max_agent_simult: '¡Has alcanzado el límite de agentes simultáneos!',
    out_of_hours: 'Agente fuera del horario laboral!',
    empty_campaign: '¡No tienes un equipo activo!'
  }
};
