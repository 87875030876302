export default {
  isoName: 'en',
  common: {
    admins_page: {
      success_save_admin: 'Administrator saved successfully!',
      delete_success_admins: 'Administrator(s) successfully deleted!'
    },
    time_group_page: {
      time_group_success_save: 'Time group saved successfully!',
      success_delete_group: 'Group deleted successfully!',
      success_delete_batch_group: 'Group(s) deleted successfully!'
    },
    country: 'Country',
    previously: 'PREVIOUSLY',
    general_error: 'An error has occurred on the server',
    dashboard: 'Dashboard',
    report: 'Report | Reports',
    documentation: 'Documentation',
    changelog: 'Changelog',
    status: 'Status',
    premium: 'Premium',
    artificial_intelligence: 'Artificial intelligence',
    contexts: 'Contexts',
    quota: 'API quota',
    start_hour: 'Start time',
    business_hours: 'Business hours',
    interval: 'Interval',
    update_interval: 'Default interval',
    initial: 'Initial',
    final: 'Final',
    end_hour: 'End time',
    security_privacy: 'Security and privacy',
    flow: 'Bot builder',
    integrate: 'Integrate',
    check_account: 'Check account',
    channel: 'Channel | Channels',
    batch: 'Batch delivery',
    contact: 'Contact | Contacts',
    config: 'Configuration | Settings',
    help: 'Help',
    about: 'About',
    indicator: 'Indicator | Indicators',
    storage: 'Storage',
    time: 'Time',
    preview: 'Preview',
    add_variable: 'Add variable',
    bold: 'Bold',
    italic: 'Italic',
    strike: 'Strike',
    underline: 'Underline',
    strikethrough: 'Strikethrough',
    paste: 'Paste',
    message_templates: 'Message templates',
    monitoring: 'Monitoring',
    title: 'Title',
    reauthentication: 'Reauthentication',
    enter_your_message: 'Enter your message',
    caption: 'Caption',
    apply: 'To apply',
    clean: 'To clean',
    message_box: 'Message box',
    light: 'Light',
    dark: 'Dark',
    slide: 'Slide',
    filter: 'Filter',
    not_applicable: 'Not applicable',
    increase: 'Increase',
    gradually_appear: 'Gradually appear',
    increase_rotated: 'Increase rotated',
    ending_message: 'Ending message',
    main_color: 'Main color',
    button_color: 'Button color',
    alert_color: 'Alert color',
    pop_up_color: 'End of service pop-up color',
    font_color: 'Font Color',
    message: 'Message',
    text: 'Text',
    appearance: 'Appearance',
    name: 'Name',
    email: 'E-mail',
    copy_token_auth: 'Copy authentication token',
    generate_token_auth: 'Generate authentication token',
    generate_api_key: 'Generate ApiKey',
    recovery_email: 'Recovery e-mail',
    attendance: 'Attendance | Attendances',
    agent: 'Agent | Agents',
    conversation: 'Conversation | Conversations',
    export: 'Export',
    variable: 'Variable | Variables',
    audit: 'Audit',
    password: 'Password',
    redefine_password: 'Redefine password',
    current_password: 'Current password',
    new_password: 'New password',
    confirm_password: 'Confirm new password',
    remember: 'Remember',
    approved: 'Approved',
    in_analysis: 'Under analysis',
    pending: 'Pending',
    unsynchronized: 'Unsynchronized',
    refused: 'Refused',
    log_in: 'Log in',
    contact_center: 'DISCOVER THE NEW CONTACT CENTER',
    message_extract: 'Message usage',
    team: 'Team | Teams',
    pause: 'Standby | Standby',
    tabulation: 'Tabulation | Tabulations',
    tags: 'Tag | Tags',
    aplication: 'Application',
    multichannel: 'Multichannel',
    import_contacts: 'Import contacts',
    time_group: 'Time group',
    account_name: 'Account name',
    ra_page_id: 'Company RA',
    ra_success:
      'Successfully authenticated on Reclame Aqui. Choose the page below.',
    apikey_generate_success: 'Your apikey was successfully generated.',
    account_password: 'Account password',
    post: 'Post',
    comment: 'Comment | Comments',
    page: 'Page | Pages',
    control: 'Control',
    api: 'API',
    rest: 'REST',
    third: 'Third',
    default: 'default',
    version: 'Version',
    period: 'Period',
    day: 'Day | Days',
    hour: 'Hour | Hours',
    client_secret: 'Secret key',
    app_id: 'Application identifier',
    week: 'Week | Weeks',
    rd_station: 'RD Station (beta)',
    telephone_exchange: 'PBX',
    integration: 'Integration | Integrations',
    user_group: 'User group',
    additional_fields: 'Custom fields',
    icon: 'Icon',
    notification: 'Notification',
    button: 'Button',
    buttons: 'Buttons',
    button_text: 'Button text',
    ad_account: 'Ad account',
    image: 'Image',
    activate: 'Activate',
    position: 'Position',
    label: 'Label',
    animation: 'Animation',
    option_title: 'Option title',
    product_id: 'Product id',
    characters: 'Characters',
    color: 'Color',
    customization: 'Customization',
    input_fields: 'Input fields',
    seconds: 'Seconds',
    remove_session: 'Remove session',
    minutes: 'Minutes',
    without_permission: 'Without permission',
    word_filter: 'Word filter',
    remote_support: 'Remote support',
    support: 'Support',
    custom_variables: 'Custom variables',
    administrator: 'Administrator | Administrators',
    settings: 'Ajustes',
    add: 'Add',
    hsm: 'HSM',
    hsm_template: 'Message Templates',
    forgot_password: 'Forgot password?',
    login_title: 'Login area',
    language: 'Language',
    search: 'Search',
    description: 'Description',
    method: 'Method',
    auth_method: 'Authentication Method',
    all: 'All',
    agents_only: 'Agents only',
    admins_only: 'Admins only',
    none: 'None',
    rows_per_page: 'Rows per page',
    rules: 'Rule | Rules',
    attention: 'Attention',
    automatic_answer: 'Automatic answer',
    attendance_automatic: 'Attendance automatic',
    initial_message: 'Initial message',
    default_team: 'Default team',
    able: 'Able',
    disabled: 'Disabled',
    token: 'Token',
    botname: 'Botname',
    botlink: 'bot link',
    tip: 'Tip | Tips',
    type: 'Type | Types',
    number: 'Number | Numbers',
    spacing: 'Spacing | Spaces',
    send_schedule: 'Send Schedule',
    send_media_with_link: 'Send media with link',
    receptive: 'Receptive',
    required: 'Required',
    login: 'Login',
    recover_password: 'Recover password',
    yes: 'Yes',
    no: 'No',
    provider: 'Provider',
    port: 'Port',
    host: 'Host',
    protocol: 'Protocol',
    user: 'User | Users',
    file: 'File | Files',
    hash: 'Hash',
    list: 'List',
    card: 'Card',
    optional: 'Optional',
    add_session: 'Add session',
    turn_on: 'Turn on',
    list_title: 'List title',
    see_items: 'See items',
    multiproduct: 'Multiproducts',
    add_option: 'Add option',
    add_product: 'Add product',
    category: 'Category',
    visibility: 'Visibility',
    models: 'Model | Models',
    clone: 'Clone',
    resend: 'Resend',
    example_template: 'Example template',
    contents: 'Contents',
    introduction: 'Introduction',
    function: 'Function',
    message_body: 'Message body',
    media_type: 'Media type',
    header_type: 'Header type',
    type_action: 'Type of action',
    close: 'To close',
    conclude: 'Conclude',
    footer: 'Footer',
    upload_icon: 'Upload icon',
    icon_color: 'Color icon',
    header: 'Header',
    body: 'Body',
    advance: 'Advance',
    parameter: 'Parameter | Parameters',
    value: 'Value',
    url: 'URL',
    right: 'Right',
    select: 'Select',
    announcement: 'Announcement',
    left: 'Left',
    other: 'Other | Others',
    form_title: 'Form title',
    copy_identifier: 'Copy identifier',
    copy: 'Copy',
    copy_webhook: 'Copy webhook',
    authenticate: 'Authenticate',
    activation_code: 'Activation code',
    restart: 'Restart',
    starting_process: 'Starting process',
    pending_auth: 'Pending',
    teams_gtw: 'Enable Teams Gateway Interface (Beta)',
    enable_batch_delivery: 'Enable Batch Delivery',
    qrcode: 'QrCode',
    enable_qrcode: 'Enable QrCode',
    previous_page: 'Back to the previous page',
    connected: 'Connected',
    disconnected: 'Disconnected',
    smtp_error: 'SMTP error',
    error_imap_and_smtp: 'SMTP and IMAP error',
    error_imap: 'IMAP error',
    login_or: 'or',
    login_email: 'Login email',
    of: 'de',
    oauth_redirect_url: 'Redirect URI',
    webhook_url: 'Notification Callback URL',
    aware: 'Aware',
    unlock_for_trust: 'Unlock in confidence',
    access_now: 'Access now',
    authenticationMethod: 'Authentication method',
    mode: 'Function Type',
    test: 'Test',
    result: 'Result',
    custom: 'Customizable',
    script_test: 'Script Test',
    lang: {
      'pt-br': 'Portuguese',
      es: 'Spanish',
      en: 'English'
    },
    list_colors: {
      yellow: 'Yellow',
      blue: 'Blue',
      green: 'Green',
      grey: 'Grey',
      purple: 'Purple',
      brown: 'Brown',
      red: 'Red',
      violet: 'Violet',
      pink: 'Pink',
      black: 'Black'
    },
    components: {
      facebook_reaction: 'Facebook Reaction',
      attachment: 'Attachment',
      receive_attachment: 'Receive attachment',
      menu: 'Menu',
      location: 'Location',
      service_note: 'Service note',
      condition: 'Condition',
      webchat_event: 'Webchat event',
      input: 'Data input',
      template_message: 'Message template'
    },
    actions: {
      self: 'Action | Actions',
      edit: 'Edit',
      remove: 'Remove',
      save: 'Save',
      back: 'Back',
      cancel: 'Cancel'
    },
    messages: {
      required_office365: 'Only for office365 users',
      image_size: 'The image should have the size of 192x192 pixels',
      auth_verify: 'Verifying authentication',
      invalid_character: 'Invalid character!',
      licence_limit_per_channel_reached: 'Licence limit per channel reached',
      channel_already_exists_in_another_licence:
        'Channel already exists in another licence',
      email_settings_not_found: 'Email settings not found',
      reauthentication_required: 'Reauthentication required',
      automatic_spacing:
        'Automatic spacing in messages avoids banning WhatsApp',
      required: '{name} is required!',
      can_be_no_space: '{name} cannot have spaces!',
      flow_invalid:
        'The defined stream has components not supported by the channel',
      invalid: '{name} is invalid!',
      no_data: 'No {name} registered!',
      incomplete_phone_number: 'DDI + DDD is required',
      no_result: 'No results found!',
      view_mode: '{name} view',
      channel_period:
        'Set how many days you want to display in the comment history',
      restart_channel: 'Do you really want to restart?',
      are_you_sure: 'Are you sure?',
      copy: 'Copied successfully!',
      you_really_delete: 'Do you really want to delete?',
      really_want_to_resend:
        'Do you really want to resend the message template for approval?\nThe analysis can take up to 24 hours.',
      redirect_to_update:
        'Could not verify all channels to resend. You will be redirected to the edit screen to select channels manually. Do you want to proceed?',
      success: 'Succssesfully done!',
      error: 'An error has occurred!',
      error_email: 'An error occurred while authenticating the email.',
      password_not_match: 'Passwords do not match',
      url_webhook: 'webhook URL not found!',
      session_end: 'End session',
      button_customization: 'Button customization',
      chat_customization: 'Chat customization',
      edit_user_information: 'Edit user information',
      can_i_help_you: 'Can I help you',
      you_really_end_service: 'Do you really want to end your service?',
      only_cell: 'Only on cell phones',
      message_color: 'User message color',
      select_image_button: 'Select an image for the button',
      character_limit: 'Character limit per message',
      welcome_message: 'Welcome message',
      select_default_image: 'Select a default image for the chat',
      selected_platform:
        'The selected platform cannot use a stream that contains the following components',
      unlock_for_trust:
        'Confidence unlock will last 48 hours. Contact the person responsible.',
      alert_newsletter:
        'Home form alerts that display when a required field is not filled in or when information is entered in an incorrect format.'
    }
  },
  signin_message: {
    login_back: 'Back to login',
    auth_remote_message:
      'User no longer has remote login permission, reset a password to gain access to the system.',
    username_password_match: 'Username and / or password do not match',
    expired_password:
      'Your password has expired, please register a new password!',
    lock_prediction:
      'Your system will be locked in {days} days. To avoid blocking, please contact the person responsible.',
    locked_system:
      'Your system has been blocked. To continue accessing the platform normally, please contact the person responsible.'
  },
  marketplace_screen: {
    messages: {
      expand_power: 'Expand your power with app integrations',
      marketplace_collection:
        'Our marketplace is a collection of apps to expand the platforms power and improve its performance.',
      choose_below:
        'Choose below the mode you want to start your new integration.'
    }
  },
  hsm_screen: {
    messages: {
      image_size_limit: 'Image size limit is 5mb',
      body_example: 'Example of body text',
      enable_device: 'Do you want to enable the template for the device?',
      disable_device: 'Do you want to disable the template for the device?',
      no_result_channel: 'No channels added!',
      no_result_dialog: 'No Dialog Channel Found',
      updated_variables: 'Updated variables',
      message_required: 'Message is required!',
      template_example_message:
        'Submit your template with examples to increase the chance of approval',
      alert_example_message:
        'Examples will be uploaded to Facebook as part of the message template approval process. if none example is informed, models may be rejected. Through facebook.'
    },
    no_registration_variable:
      'This record does not have the variables filled in, correct it to use normally!',
    type_repetition: 'Type values ​​in acction type are repeated!',
    status_error: 'Error',
    status_reject: 'Rejected',
    in_analysis: 'This model is being analyzed by a Meta team.',
    type_to_replace: 'Type to replace:',
    template_exists: 'Template with that name already exists.',
    select_template: 'Select a template to start.',
    select_models: 'Select one of the templates to continue',
    choose_model: 'Choose model',
    button_type_quick_reply: 'Quick reply',
    button_type_action: 'Actions',
    phone: 'Phone',
    head_type_none: 'None',
    head_type_text: 'Text',
    head_type_image: 'Image',
    head_type_document: 'Document',
    head_type_video: 'Video',
    message_button_limit: 'Maximum button limit reached.',
    message_option_limit: 'Maximum option limit reached.',
    message_section_limit: 'Maximum session limit reached.',
    message_example_not_found: 'Enter an example for each variable.',
    message_device_not_found: 'No channels enabled.'
  },
  hsm_categories: {
    account_update: 'Account update',
    payment_update: 'Payment update',
    personal_finance_update: 'Personal Finance Update',
    shipping_update: 'Upload update',
    reservation_update: 'Reservation update',
    issue_resolution: 'Problem solving',
    appointment_update: 'Appointment update',
    transportation_update: 'Transport update',
    ticket_update: 'Ticket update',
    alert_update: 'Update alert',
    auto_reply: 'Automatic answer'
  },
  hsm_visibility: {
    all: 'All',
    agents_only: 'Agents only',
    admins_only: 'Admins only',
    none: 'None'
  },
  channel_screen: {
    my_business: {
      configuration_auth: 'Authentication Configuration'
    },
    messages: {
      generic_type: 'Generic channel type',
      webhook: {
        inbound: 'Input Hook',
        outbound: 'Output Hook'
      },
      include_channel: 'Include Channel',
      configuration_bsp: 'BSP configuration',
      check_account: 'Instagram Direct account is commercial!',
      webhook_register:
        'The Webhook field will be available for viewing after successfully registering this channel',
      edit_channel: 'Edit Channel',
      session_open_channel_remove:
        'There are open sessions for this channel, it is necessary to remove the sessions before finalizing the deletion',
      generic_channel_edit: 'Edit Generic Channel (Beta)',
      generic_channel_add: 'Include Generic Channel (Beta)',
      wait_one_minutes: 'Wait 1 minute to carry out the modification',
      enable_receptive: 'Do you want to enable the receptive?',
      disable_receptive: 'Do you want to disable the receptive?',
      enable_channel: 'Do you want to enable this channel?',
      disable_channel: 'Do you want to disable this channel?',
      token_generate:
        'When generating a new token, the old ones will stop working. Do you really want to generate a new token?',
      apikey_generate:
        'When generating a new ApiKey, the old ones stopped working. Do you really want to generate a new ApiKey?',
      apikey_info: 'Wait 1 minute while we update your apikey.'
    },
    webchat: {
      ask_information: 'Ask for information before starting the chat',
      display_button_message: 'Display button message after how many seconds?',
      display_button_chat: 'Display the chat button after how many seconds?',
      choose_animation: 'Choose the animation of the chat view',
      keep_keyboard: 'Keep the keyboard open after sending a message?',
      close_chat_after: 'Close chat after user has logged out?',
      allow_ballon: 'Allow balloon message to close',
      display_welcome_message: 'Display welcome message when starting webchat',
      show_message_chat: 'Show message next to the chat',
      enable_active_webchat: 'Enable Active Webchat (Beta)',
      image_dimension:
        'The value entered for the icon field is not a valid image dimension.',
      information_starting:
        'We need some information before starting the service',
      display_online: 'Display as online if one or more agents are connected.',
      select_service:
        'Select the services that will display the online chat if you have agents connected. Leave blank for all calls',
      webchat_users_notification:
        'This feature allows for a two-way conversation with Webchat users, such as receiving notifications of new messages, histories and unified registration. Once enabled, Webchat offers the user the ability to option to install on your home-screen functioning as an app.',
      select_image_webchat:
        'Select an image for the Webchat icon The image must be 512x512 pixels in size.'
    },
    teams: {
      microsoft_app_id: 'Microsoft App ID',
      microsoft_app_password: 'Microsoft App Password'
    }
  },
  webchat: {
    tip:
      'You will need to enter the code once on each page where you want to display Webchat. Insert the code above in the tag (or at the top of the body) of your page. If you need a direct link to share with your customers, use the link below.'
  },
  active_contact: {
    active_contact_title:
      'Choose the type of message template you want to use to start a new conversation!',
    active_contact_confirmation: 'Confirm the chosen message template!',
    models_not_found: 'No models were found.',
    labels: {
      campaigns: {
        label: 'Teams',
        placeholder: 'Select a team'
      },
      channels: {
        label: 'Channels',
        placeholder: 'Select a channel'
      },
      active_sessions: 'Active sessions',
      custom_variable:
        'The content of the variables present in the model can be customized using the fields below',
      variables: 'Variables',
      filter: 'Filter',
      name: 'Name',
      message: 'Message',
      category: 'Category'
    },
    customer_care: {
      title: 'Customer Care',
      description: 'Remaining time since the last user message'
    },
    click_to_whatsapp: {
      title: 'Click to WhatsApp (CTWA)',
      description: 'Conversation started by campaign'
    },
    service: {
      title: 'Service',
      description: 'Conversation started by the business',
      tip: 'Allows you to resolve customer queries.'
    },
    marketing: {
      title: 'Marketing',
      description: 'Conversation started by the business',
      tip:
        'Allows you to achieve a wide range of goals, from generating awareness to boosting sales and redirecting customers. Examples include ads for new products, services or features, targeted promotions/offers, and cart abandonment reminders.'
    },
    utility: {
      title: 'Utility',
      description: 'Conversation started by the business',
      tip:
        'Allows tracking of user actions or requests. Examples include acceptance confirmation, order/delivery management (e.g., delivery update), account updates or alerts (e.g., payment reminder), or feedback surveys.'
    },
    authentication: {
      title: 'Authentication',
      description: 'Conversation started by the business',
      tip:
        "Allows you to confirm the user's identity using disposable access codes at various stages of the login process (e.g., account verification and recovery or integrity challenges)."
    },
    buttons: {
      cancel: 'Cancel',
      next: 'Next',
      back: 'Back',
      send: 'Send',
      apply: 'Apply',
      clear: 'Clear',
      confirm: 'Yes, confirm',
      send_free_text: 'Send free text'
    },
    dialog: {
      title: 'Do you want to open a new session?',
      description:
        'You already have an active session in progress. Starting a new session will generate an additional charge.'
    }
  },
  authentication_template: {
    example_message: 'Hello, your authentication code is <b>{{1}}</b>.',
    advisory_message: 'For your safety, do not share it.',
    button_message: 'Copy code',
    footer_message: 'This code expires in',
    example_footer: 'This code expires in 10 minutes',
    code_delivery: {
      title: 'Code delivery',
      description:
        "Meta will persist in the attempt to deliver a message to the WhatsApp user during the period designated as the message's lifetime. By default, authentication templates have a lifetime of 10 minutes. If delivery is not successful within this range, attempts will be stopped and the message will be discarded."
    },
    copy_code: {
      title: 'Copy code',
      description:
        'Simplified basic authentication. Your customers can easily copy and paste the code into your application to ensure quick and secure access.',
      button_info:
        'Customers tap the button to copy and paste the code into their own app.',
      button_tip:
        'The "Copy code" option works best for customers who request the code on a device that does not contain their WhatsApp account.'
    },
    text_button: {
      title: 'Button text',
      description:
        'You have the possibility to customize the button text according to your preferences and needs, adapting it in the way that suits you best.'
    },
    message_content: {
      title: 'Message content',
      description:
        'It is not possible to edit the content of the authentication message templates. You can add more content from the options below.'
    },
    labels: {
      add_advisory: 'Add security advisory',
      add_expires: 'Add code expiration time',
      expires_in: 'Expires in',
      expires: 'Expiration time',
      variable: 'Variable',
      placeholder: 'Placeholder'
    }
  },
  forgot_password: {
    success:
      'A link to change the password was sent to email {email}, check your inbox in the next few minutes.'
  },
  errors: {
    only_json_file: 'Only Json files are allowed',
    access_denied: 'Access denied',
    access_denied_subtitle: 'You do not have permission to access this page.',
    not_found: 'Page not found',
    not_found_subtitle: 'The page you requested was not found.',
    failed_server_remote:
      'Failed to authenticate with SAML2. Authenticator without configured e-mail attribute.',
    failed_authenticate_remote_client:
      'Failed to authenticate with SAML2. Client {host} not configured / identified.',
    failed_authenticate_unaccredited:
      'Failed to authenticate with SAML2. Unregularized user.',
    failed_authenticate_profile:
      'Failed to authenticate with SAML2. User without defined profile.',
    authenticate_remote_not_possible:
      'You can not authenticate with remote server at the time.',
    no_active_team: 'You do not have an active team',
    forgot_password_invalid_email:
      'We were unable to find any email address for password recovery of the informed user.',
    forgot_password_user_not_found:
      'We cannot find a user with that e-mail address.',
    service_unavailable: 'Service unavailable',
    user_cannot_reset_password:
      'To change the access password, contact the administrator of the remote authentication provider.',
    user_blocked_to_access: 'Your login is temporarily blocked!',
    user_or_password_wrong: 'Username and/or password do not match',
    user_or_password_wrong_with_recaptchav2:
      'Username and/or password do not match',
    current_password_is_invalid: 'Current password is invalid!',
    passwords_do_not_match: 'The passwords entered do not match!',
    password_dont_have_min_length:
      'Password does not have a minimum length of {minLength} characters.',
    password_dont_have_rules:
      'Password must have at least one letter, a number and a special character.',
    password_have_numeric_sequence:
      'It is not possible to insert a numeric string within the password. Ex .: ..012 .., ..654 .. ',
    password_have_chars_repeated:
      'It is not possible to insert repeated characters within the password. Ex .: ..aa .., ..22 .., ..-- .. ',
    password_dont_register_again:
      'You cannot register a previously used password.',
    flow_not_exist: 'The selected bot builder does not exist!',
    ra_auth_error: 'Error when authenticating in the Reclame Aqui API!',
    channel_not_provided: 'Channel not found',
    channel_generate_apikey_error: 'We had a problem generating the apikey',
    recaptcha_low_score: 'Please, verify the Recaptcha.',
    no_recaptcha_token_response: 'Error verifying recaptcha.',
    max_sessions: 'Error exceeding session limit.',
    max_product_items: 'Error exceeding product limit.',
    duplicate_product_id: 'Error sending duplicate product ids.',
    update_error_in_less_than_30_days:
      'Message template cannot be updated more than 10 times in less than 30 days.',
    update_error_in_less_than_24_hours:
      'Message template cannot be updated more than once in less than 24 hours.',
    not_found_userhub:
      'The channel user was not found, check if the channel is enabled.',
    not_found_meta_id: 'The message template was not found in the Meta.'
  },
  invalid: {
    login_disabled:
      'Your login is disabled. Please contact your administrator!',
    max_agent_simult: 'You have reached the limit of simultaneous agents!',
    out_of_hours: 'Agent outside working hours!',
    empty_campaign: "You don't have an active team!"
  }
};
